import React from "react";
import "@coremedia/headless-client-app/src/components/App/App.scss";
import { SiteContext, SiteContextProvider } from "@coremedia/headless-client-app/src/context/SiteContextProvider";

interface Props {
  siteContext: SiteContext;
  siteId: string;
  currentNavigation?: Array<string>;
}

const PageWrapper: React.FC<Props> = ({ siteContext, siteId, currentNavigation, children }) => {
  const siteContextValue: SiteContext = {
    ...siteContext,
    currentNavigation: currentNavigation,
  };
  return (
    <SiteContextProvider siteContextValue={siteContextValue} id={siteId}>
      {children}
    </SiteContextProvider>
  );
};

export { PageWrapper };
