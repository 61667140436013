import {Layout} from "./src/components/layout";
import {PageWrapper} from "./src/components/pageWrapper";
import React from "react";

export const wrapRootElement = ({ element }) => (
  <Layout>{element}</Layout>
)

export const wrapPageElement = ({ element, props }) => {
  const current = props.path.split("/").filter((item) => {
    return item !== null && item !== "";
  })
  return <PageWrapper siteContext={props.pageContext.siteContext} siteId={props.pageContext.siteId} currentNavigation={current}>{element}</PageWrapper>
}
