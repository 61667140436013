import React from "react";
import { Link as ReactLink } from "react-router-dom";
import { Link as GatsbyLink } from "gatsby";
import { useSiteContextState } from "../../context/SiteContextProvider";
import { getLink } from "../../utils/Link/LinkUtils";

interface Props {
  to: any;
  className?: string;
  role?: string;
  title?: string;
}

export const Link: React.FC<Props> = ({ to, className, role, children, title }) => {
  const { rootSegment } = useSiteContextState();
  const linkTarget = getLink(to, rootSegment);

  let link;

  if (process.env.REACT_LINK_TYPE === "gatsby") {
    link = (
      <GatsbyLink to={linkTarget} className={className} role={role} title={title}>
        {children}
      </GatsbyLink>
    );
  } else {
    link = (
      <ReactLink to={linkTarget} className={className} role={role} title={title}>
        {children}
      </ReactLink>
    );
  }

  return link;
};
export default Link;
