import React, { ReactNode } from "react";
import { PageGridPlacement } from "../queries/fragments/__generated__/PageGridPlacement";
import RootQuery from "../queries/RootQuery";
import { Navigation } from "../queries/fragments/Navigation";

import Loading from "../components/Loading/Loading";
import { metaDataForResponsiveDevices } from "../utils/Preview/MetaData";
import { LinkableWithLocale } from "../queries/fragments/__generated__/LinkableWithLocale";
import { ApolloClientAlert, PageNotFoundAlert } from "../components/Error/Alert";
import Header from "../components/Header/Header";
import FooterNavigation from "../components/Footer/FooterNavigation";
import Footer from "../components/Footer/Footer";

export interface SiteContext {
  rootSegment: string;
  navigation?: Navigation;
  placements?: Array<PageGridPlacement | null> | null;
  localizedVariants?: Array<LinkableWithLocale>;
  currentNavigation?: Array<string>;
}

const siteContext = React.createContext<SiteContext>({
  rootSegment: "",
});

export const useSiteContextState = (): SiteContext => {
  const context = React.useContext(siteContext);
  if (context === undefined) {
    throw new Error("useSiteContextState must be used within a SiteContextProvider");
  }
  return context;
};

interface Props {
  children: ReactNode;
  siteContextValue: SiteContext;
  id?: string;
}

export const SiteContextProvider: React.FC<Props> = ({ children, siteContextValue, id }) => {
  return (
    <siteContext.Provider value={siteContextValue}>
      <div className={"cm-grid"} {...metaDataForResponsiveDevices(id)}>
        <Header />
        {children}
        <FooterNavigation />
        <Footer />
      </div>
    </siteContext.Provider>
  );
};
